import { stateError, stateInitial, stateLoaded, stateLoading } from '@shared/models/state';
import { AppItem, getApps, getAppsAdmin, postAppRole, postAppSettings, patchAppSettings } from '@shared/services/apps';
import { type TwinResponse } from '@shared/services/twinManagement';
import axios from 'axios';
import { defineStore } from 'pinia';
import { App, computed, ref } from 'vue';

export type Applink = {
  // Expression which should match the according link uri
  uriExpression: string, 
  // The name of the route
  routeName: string
};

export const useAppStore = defineStore("apps", () => {

  const appsLoadingState = ref({ ...stateInitial, query: {}, result: [] as AppItem[] })

  const applinks = ref<Applink[]>([]); 

  function setApplinks(links: Applink[]){
    applinks.value = applinks.value.concat(links);
  }


  // Get a router link for a uri, this is currently used to retrieve a link for twin editing, using the 
  // Twin edit URI: twin-edit.${twin.type}.${twin.templateUuid}.${twin.uuid}
  function getLink(uri: string) {
    const link =  applinks.value.find(item => (new RegExp(item.uriExpression).test(uri)))
    // If no link is found, navigate to home
    if(link === undefined) {
      return { uriExpression: 'error', routeName: 'home'}
    }
    return link;
  }

  function getTwinEditLink(twin: TwinResponse) {
    return getLink(`twin-edit.${twin.type}.${twin.templateUuid}.${twin.uuid}`);
  }

  async function loadAppsOverview(query, reload?: boolean) {
    if(appsLoadingState.value.loading) {
      return;
    }
    if(!reload && appsLoadingState.value.loaded) {
      return;
    }
    appsLoadingState.value = { ...stateLoading, query, result: appsLoadingState.value.result };
    const response = await getApps(query).catch((e) => {
      appsLoadingState.value = { ...appsLoadingState.value, ...stateError };
      throw e;
    })
    appsLoadingState.value = { ...stateLoaded, result: response.data.result, query }
    // appsLoadingState.value = { ...stateLoaded, result: response.data.result, query }
  }

  const appsSettingsState = ref({ ...stateInitial, result: [] as AppItem[] })

  async function loadAppsSettings() {
    appsSettingsState.value = { ...stateLoading, result: appsSettingsState.value.result };
    const response = await getAppsAdmin().catch(e => {
      appsSettingsState.value = { ...appsSettingsState.value, ...stateError };
      throw e;
    })
    appsSettingsState.value = { ...stateLoaded, result: response.data.result };
  }

  const appsSettingsLoading = ref(false);

  async function saveAppSettings(appId: string, settings: AppItem) {
    appsSettingsLoading.value = true;
    const response = await patchAppSettings(appId, settings)
      .finally(() => {
        appsSettingsLoading.value = false;
      })
    loadAppsSettings();
    return response;
  }

  async function createAppSettings(settings: AppItem){
    appsSettingsLoading.value = true;
    const response = await postAppSettings(settings)
      .finally(() => {
        appsSettingsLoading.value = false;
      })
    loadAppsSettings();
    return response;
  }

  async function addAppRole(app: string, role: string) {
    await postAppRole({app, name: role});
  }

  async function deleteAppRole(id: string) {
    await deleteAppRole(id);
  }

  const sortedResult = computed(() => {
    return appsLoadingState.value.result.filter(item => item.order >=0).toSorted((a, b) => a.order - b.order)
  })

  return {
    loadAppsOverview,
    appsLoadingState,
    loadAppsSettings,
    appsSettingsState,
    saveAppSettings,
    createAppSettings,
    appsSettingsLoading,
    addAppRole, 
    deleteAppRole,
    getLink, 
    getTwinEditLink,
    setApplinks,
    sortedResult
  }
})