export const groupManagementRoutes = [
  {
    path: "/group-management",
    name: "groupManagement",
    component: () => import("@shared/components/groupManagement/GroupManagement.vue"),
    meta:
    {
      appClientId: "smx-user-mgmt",
      breadcrumbs: [{
        label: 'groupManagement',
        route: { name: "groupManagement" }
      }]
    }
  },
  {
    path: "/group-management/:id",
    name: "groupManagementDetail",
    props: true,
    component: () => import("@shared/components/groupManagement/GroupDetail.vue"),
    meta:
    {
      appClientId: "smx-user-mgmt",
      breadcrumbs: [{
        label: 'groupManagement',
        route: { name: "groupManagement" }
      }]
    }
  },
]