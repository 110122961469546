<template lang="pug">
.label
  label.smx-label(
    :class="{ 'md:text-right': true, required: props.required }",
    :style="{ 'min-width': props.minWidth }"
  ) {{ props.label }}
  SmxInfo(
    v-if="description",
    :title="props.label",
    :content="props.description"
  )
slot(class="max-md:mb-3")
</template>

<script setup lang="ts">
import SmxInfo from "@shared/components/elements/SmxInfo.vue";

const props = defineProps<{
  label?: string;
  description?: string;
  required?: boolean;
  minWidth?: number;
}>();
</script>

<style>
.form-grid > .label {
  justify-content: flex-end;
  align-items: start;
  display: flex;
  max-width: 150px;
}

@media only screen and (max-width: 768px) {
  .form-grid > .label {
    justify-content: flex-start;
  }
}
</style>
