<template lang="pug">
h1.mb-8 {{ $t('changePassword') }}
smx-form 
  smx-form-item(:label="$t('password')")
    el-input(v-model="password" type="password" show-password)
  smx-form-item(:label="$t('repeatPassword')")
    el-input(v-model="passwordRepeat" type="password" show-password)
  template(#actions)
    el-button(type="primary" @click="save") {{ $t('save') }}
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useMyAccountStore } from '../../stores/myAccount';
import { patchPassword } from '@shared/services/myAccount';

const password = ref("")
const passwordRepeat = ref("")

const { t } = useI18n();

const myAccountStore = useMyAccountStore();

onMounted(() => {
  myAccountStore.initUserData(false);
})

const loading = ref(false);

async function save() {
  if (password.value.length < 8) {
    ElNotification({
      title: t('passwordMinLength'),
      type: "error"
    })
    return;
  }
  if (password.value !== passwordRepeat.value) {
    ElNotification({
      title: t('passwordsDontMatch'),
      type: "error"
    })
    return;
  }
  loading.value = true;

  const response = await patchPassword(myAccountStore.userData.id, { password: password.value })
    .then(
      () => {
        ElNotification({
          title: t('success'),
          message: t('passwordChanged')
        })

       },
      error => {
        ElNotification({
          title: t('error'),
          message: t('genericError'),
          type: "error"
        })
      })
    .finally(() => {
      loading.value = false;
    });

}

</script>
