import { clientId } from "./constants";

export const itAssetManagementRoutes = [
  {
    path: "/it-assets",
    name: "itAssetManagement",
    component: () => import("@shared/itAssetManagement/views/ItAssetTypeSelection.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [{
        label: 'itAssets',
        route: { name: "itAssetManagement" }
      }]
    }
  },
  {
    path: "/it-assets/:template",
    name: "itAssetManagementList",
    props: true,
    component: () => import("@shared/itAssetManagement/views/ItAssetManagementListView.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'itAssets',
          route: { name: "itAssetManagement" }
        },
      ]
    }
  },
  {
    path: "/it-assets/:template/edit/:id?",
    name: "itAssetManagementDetail",
    props: true,
    component: () => import("@shared/itAssetManagement/views/ItAssetManagementDetailView.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'itAssets',
          route: { name: "itAssetManagement" }
        }
      ]
    }
  },
  {
    path: "/it-asset-reports/assets",
    name: "itAssetReports",
    component: () => import("@shared/itAssetManagement/views/ReportItAssets.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'itAssets',
          route: { name: "itAssetReports" }
        }
      ]
    }
  },
  {
    path: "/it-asset-reports/changelogs",
    name: "itAssetReportChangelogs",
    component: () => import("@shared/itAssetManagement/views/ReportItAssetsChangelog.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'changelog',
          route: { name: "itAssetReportChangelogs" }
        }

      ]
    }
  },
  {
    path: "/it-asset-access",
    name: "itAssetAccess",
    component: () => import("@shared/itAssetManagement/views/ItAssetManagementAccess.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'accessRights',
          route: { name: "itAssetAccess" }
        }

      ]
    }
  },

]