import { clientId } from "./constants";

export const qmdRoutes = [
    {
        path: '/quality-agreements',
        name: 'qmdDocumentList',
        component: () => import("@shared/qualityManagementDocuments/views/DocumentsList.vue"),
        meta: {
            appClientId: clientId,
            breadcrumbs: [
                {
                    label: 'documents',
                    route: {name: 'qmdDocumentList'}
                }
            ]
        }
    },
    {
        path: '/quality-agreements/:id',
        name: 'qmdDocumentDetail',
        component: () => import("@shared/qualityManagementDocuments/views/DocumentDetail.vue"),
        props: true,
        meta: {
            appClientId: clientId,
            breadcrumbs: [
                {
                    label: 'documents',
                    route: {name: 'qmdDocumentList'}
                }
            ]
        }
    },

    {
        path: '/qmd-categories/',
        name: 'qmdCategoriesList',
        props: true,
        component: () => import("@shared/qualityManagementDocuments/views/CategoryList.vue"),
        meta: {
            appClientId: clientId,
            breadcrumbs: [
                {
                    label: 'categories',
                    route: {name: 'qmdCategoriesList'}
                }
            ]
        }
    },

    {
        path: '/qmd-categories/:id',
        name: 'qmdCategoryDetail',
        props: true,
        component: () => import("@shared/qualityManagementDocuments/views/CategoryDetail.vue"),
        meta: {
            appClientId: clientId,
            breadcrumbs: [
                {
                    label: 'categories',
                    route: {name: 'qmdCategoriesList'}
                }
            ]
        }
    },

]