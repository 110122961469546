<template lang="pug">
.sidebar(:style="{width: width }" )
  .sidebar-content(v-if="visibleAfterAnimation" :style="{width: mq.mdPlus ? expandedWidth + 'px' : '100vw', opacity: visible ? 1 : 0 }" class="max-md:pl-5 max-md:pr-5 md:pl-10 md:pr-10")
    SmxMenuItem(:content="menu?.content" @link-clicked="linkClicked")
    hr.mb-8
    SmxMenuItem(:content="[{ type: 'action', onClick: openMyAccount, name: $t('myAccount') }]")
    SmxMenuItem(:content="[{ type: 'action', onClick: logout, name: $t('logout') }]")
  //- .sidebar-switch-container(v-if="mq.mdPlus" :style="{ width: expandSwitchWidth}")
  .sidebar-switch(v-if="mq.mdPlus" :style="{width: `${expandSwitchWidth}px`, height: `${expandSwitchWidth}px` }" @click="menuStore.setMenuVisible(!visible)")
    el-icon()
      Fold(v-if="visible")
      Expand(v-else)
MyAccountPopover(v-model="myAccountVisible")

</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import { useMenuStore } from '../stores/menu';
import SmxMenuItem from './SmxMenuItem.vue';
import { useMq } from 'vue3-mq';
import { useAuthStore } from '../stores/auth';
import MyAccountPopover from "@shared/components/myAccount/MyAccountPopover.vue";

const mq = useMq();

const menuStore = useMenuStore();

const menu = computed(() => menuStore.menu)

const visible = computed(() => menuStore.isMenuVisible)

// NOTE: the  menu has to vanish completely after closing animation has ended
let visibleAfterAnimationTimeout = null;
watch(visible, (isVisible) => {
  if (visibleAfterAnimationTimeout !== null) {
    clearTimeout(visibleAfterAnimationTimeout);
  }
  if (isVisible) {
    visibleAfterAnimation.value = true;
  }
  else {
    visibleAfterAnimationTimeout = setTimeout(() => {
      visibleAfterAnimation.value = false;
    }, 500)
  }

})

const visibleAfterAnimation = ref(false);
onMounted(() => {
  if(visible.value) {
    visibleAfterAnimation.value = true;
  }
})

const expandedWidth = 290;
const expandSwitchWidth = 40;

// If visible the menu is either the full size on mobile or expandedWidth
// When hidden, the menu is either 20px on Desktop, to view the menu notch, or 0 on mobile
const width = computed(() => visible.value ? (mq.mdPlus ? `${(expandedWidth + expandSwitchWidth)}px` : '100vw') : (mq.mdPlus ? `${expandSwitchWidth}px` : '0'))

function linkClicked() {
  if (mq.mdMinus) {
    menuStore.setMenuVisible(false);
  }
}

const authStore = useAuthStore(); 

function logout() {
  authStore.logout();
}

const myAccountVisible = ref(false);

function openMyAccount() {
  myAccountVisible.value = true;
}

</script>

<style scoped>
.sidebar {
  position: relative;
  width: 0;
  transition: width 0.5s ease-in-out;
  height: 100%;
}

.sidebar-content {
  min-height: 100%;
  max-height: 100%;
  overflow: scroll;
  box-shadow: inset -1px 0 4px 0 var(--color-shadow);
  background-color: white;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}

.sidebar-content>.menu-item {
  font-weight: bold;


}


.sidebar-switch {
  z-index: 99;
  box-shadow: inset -1px 4px 4px -4px var(--color-shadow);
  right: 4px;
  position: absolute;
  cursor: pointer;
  top: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 2px 50% 50% 0;
}
</style>