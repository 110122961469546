<template lang="pug">
h1.mb-8 {{ $t('myData') }}
smx-form(v-loading="myAccountStore.userDataLoadingState.loading")
  smx-form-item(:label="$t('username')")
    span {{ editableData.username }}
  smx-form-item(:label="$t('firstName')")
    el-input(v-model="editableData.firstName")
  smx-form-item(:label="$t('lastName')")
    el-input(v-model="editableData.lastName")
  smx-form-item(:label="$t('email')")
    el-input(v-model="editableData.email")
  smx-form-item(:label="$t('language')")
    LanguageSelect(v-model="language")

  template(#actions)
    el-button(v-loading="saveLoading" type="primary" @click="saveChanges") {{ $t('save') }}

</template>


<script lang="ts" setup>
import { stateInitial, type LoadState, stateLoading, stateError, stateLoaded } from '../../models/state';
import { onMounted, ref, computed, watch } from 'vue';
import LanguageSelect from "./components/LanguageSelect.vue"
import { useAuthStore } from '../../stores/auth';
import { useMyAccountStore } from '../../stores/myAccount';
import { type MyAccountData } from '../../services/myAccount';
import { useMyAccount } from '../../hooks/myAccount';
import { type KeyCloakUserPatch } from '../../services/userManagementKeyCloak';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';

const editableData = ref<KeyCloakUserPatch>({});

const authStore = useAuthStore();

const myAccountStore = useMyAccountStore();

const userData = computed(() => myAccountStore.userData);

function setData() {
  editableData.value = { ...userData.value }
}

watch(userData, setData)

const language = computed({
  get() {
    return editableData.value?.attributes?.language[0];
  },
  set(val) {
    myAccountStore.setLanguage(val);
    editableData.value.attributes.language = [val];
  }
});

async function loadData() {
  myAccountStore.initUserData(false);
}

const saveLoading = ref(false);

const { t } = useI18n();

async function saveChanges() {
  saveLoading.value = true;
  await myAccountStore.updateUserData(editableData.value).finally(() => {
    saveLoading.value = false;
  }).then(
    () => {
      ElNotification({
        title: t("changesSaved"),
        type: "success"
      })
    },
    err => {
      ElNotification({
        title: t("genericError"),
        type: "error"
      })
    });
}

onMounted(() => {
  loadData();
  setData();
})

</script>