<template lang="pug">
el-select(v-model="model")
  el-option(v-for="option in options" :key="option.value" :value="option.value" :label="option.label")
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: string,
}>();

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get(){
    return props.modelValue
  },
  set(val){
    emit('update:modelValue', val)
  }
}) 

const options = [
  {
    label: "🇬🇧 English",
    value: "en"
  },
  {
    label: "🇩🇪 Deutsch",
    value: "de"
  }
]

</script>