import axios, { AxiosPromise, AxiosResponse } from "axios";
import * as preferences from "../preferences";
import { UserAttributes } from './users';

/**
 * Requests for KeyCloak backed authentication  
 */

export interface LoginRequestKeyCloak {
  grant_type: 'password',
  client_id: string,
  username: string,
  password: string
}

export interface LoginResponseKeyCloak {
  access_token: string,
  expired_in: number,
  refresh_expires_in: number,
  refresh_token: string,
  token_type: string,
  'not-before-policy': number,
  session_state: string,
  scope: string
}

export function loginKeyCloak(request: LoginRequestKeyCloak, realmName: string): AxiosPromise<LoginResponseKeyCloak> {
  const params = new URLSearchParams();
  params.append('client_id', request.client_id);
  params.append('username', request.username);
  params.append('password', request.password);
  params.append('grant_type', 'password');

  return axios.post(`/api/auth/realms/${realmName}/protocol/openid-connect/token`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export function refreshKeyCloak(refreshToken: string, clientId: string, realmName: string): AxiosPromise<LoginResponseKeyCloak> {
  const params = new URLSearchParams();
  params.append('client_id', clientId);
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);

  return axios.post(`/api/auth/realms/${realmName}/protocol/openid-connect/token`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

}

/**
 * Requests for eqAccount backed authentication
 */

export interface LoginRequest {
  email: string;
  password: string;
  accepted: boolean;
}

export interface Token {
  refreshToken: string;
  jwtToken: string;
}

export interface RegistrationRequest {
  email: string;
  password: string;
  signupKey: string;
  t_and_c: boolean;
  username: string;
  attributes: {};
}
export interface TokenResponse {
  refresh: string;
  token: string;
}

export function forgotPassword(email: string) {
  return axios.post(`${preferences.authApiRoot}/forgot-password`, {
    email,
  });
}

export function resetPassword(token: string, newPassword: string) {
  return axios.post(`${preferences.authApiRoot}/reset-password`, {
    token,
    newPassword,
  });
}

export function login(
  username: string,
  password: string,
  termsAndConditions: boolean
) {
  const request: LoginRequest = {
    email: username,
    password,
    accepted: termsAndConditions,
  };
  return axios.post<TokenResponse>(preferences.authApiRoot + "/login", request);
}

export function createAccount(
  email: string,
  username: string,
  password: string,
  tAndC: boolean
) {
  const request: RegistrationRequest = {
    email,
    password,
    t_and_c: tAndC,
    username,
    signupKey: preferences.singupKey,
    attributes: {},
  };
  return axios.post<TokenResponse>(
    preferences.authApiRoot + "/sign-up",
    request
  );
}

export const refreshToken = (refresh: string): AxiosPromise<{ token: string, refresh: string }> => {
  const request = { refresh };
  return axios.post(preferences.authApiRoot + "/renew-token", request);
};
