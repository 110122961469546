/**
 * Breadcrumbs can either be set in the router meta data, for static breadcrumbs,
 * or through the store, for dynamic breadcrumbs. The store is prioritized over route metadata. 
 * When using the store, don't forget to remove the breadcrumbs when leaving a view. 
 */

import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

export interface BreadCrumb {
  label: string,
  // TODO: check vue router route type 
  route: any,
  keepLabelWithoutTranslation?: boolean
}

export interface SmxMenuItem {
  id: string, 
  type: "category" | "routerlink" | "action" 
  // If set, the children will be placed in an expandable dropdown Menu
  isDropDown?: boolean,
  name: string,
  translate?: boolean,
  children?: SmxMenuItem[],
  // Used for type routerlink, set as target
  route?: RouteLocationRaw,
  // Used for type action, invoked on click
  onClick?: Function
}

export interface SmxMenu {
  content: SmxMenuItem[]
}


export const useMenuStore = defineStore("breadcrumbs" , () => {

  const breadCrumbs = ref<BreadCrumb[] | null>(null);

  function setBreadCrumbs(crumbs: BreadCrumb[]) {
    breadCrumbs.value = crumbs;
  }

  function unsetBreadCrumbs(){
    breadCrumbs.value = null;
  }

  const isMenuVisible = ref(true);

  function setMenuVisible(isVisible: boolean) {
    isMenuVisible.value = isVisible;
  }

  const menu = ref(null);

  function setMenu(newMenu: SmxMenu){
      menu.value = newMenu;
  }

  const openDropDownMenus = ref<Record<string, boolean>>({});

  return {
    menu,
    setMenu,
    isMenuVisible,
    setMenuVisible,
    breadCrumbs, 
    setBreadCrumbs, 
    unsetBreadCrumbs,
    openDropDownMenus,
  }
});