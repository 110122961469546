import axios, { AxiosPromise, AxiosResponse } from 'axios'

export type MyAccountData = {
    id: string,
    username: string,
    createdTimestamp: number,
    enabled: boolean,
    firstName: string,
    lastName: string,
    email: string,
    emailVerified: boolean,
    requiredActions: string[],
    attributes: {
      language: string[],
      picture: string[]
    }
}


export function getUserSelf(): AxiosPromise<MyAccountData> {
  return axios.get(`/api/portal/users/_me`)
}

export function patchPassword(userId: string, request: { password: string, userLabel?: string }) {
  return axios.patch(`/api/portal/users/${userId}/reset-password`, request)
}