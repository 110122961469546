import { useAppStore } from '@shared/stores/apps';
import { onMounted } from 'vue';

export function initItAssetManagementApp() {
  const appStore = useAppStore();

  onMounted(() => {
    appStore.setApplinks([
      {
        uriExpression: 'twin-edit.asset.it.*',
        routeName: "itAssetManagementDetail"
      },
    ])

  })
}