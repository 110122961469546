import axios, { type AxiosRequestConfig, type AxiosError, InternalAxiosRequestConfig } from "axios";

import { refreshKeyCloak, refreshToken } from "./auth";
import { publicPages } from "../router/loginGuard";
import { useAuthStore } from "../stores/auth";
import type { Router } from "vue-router";
import { isRef } from 'vue';

function getToken() {
  const authStore = useAuthStore();
  return authStore.tokenState;
}

// TODO: exclude other base urls and registration endpoints, refresh token
export const AuthInterceptor =
  ({ postLogout }: { postLogout: Function }, router: Router) =>
    (config: InternalAxiosRequestConfig) => {

      // Don't add token on public routes
      if (publicPages.includes(router.currentRoute.value.path)) {
        return config;
      }
      const authStore = useAuthStore();

      if (authStore.tokenState.refresh === null) {
        authStore.logout();
        postLogout();
        return config;
      }
      const token = getToken().access;
      if (token != null && config.headers) {
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    };

let isRefreshing = false;
let requestQueue: Array<{
  resolve: (token: string) => void;
  reject: (reason?: any) => void;
}> = [];

export const AuthErrorInterceptor =
  ({ postLogout }: { postLogout: Function }) =>
    (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & {
        _retry: boolean;
      };
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        if (isRefreshing) {
          // already refreshing, push the request to the queue
          return new Promise((resolve, reject) => {
            requestQueue.push({ resolve, reject });
          })
            .then((token) => {
              if (originalRequest.headers) {
                originalRequest.headers.Authorization = "Bearer " + token;
              }
              return axios(originalRequest);
            })
            .catch((err) => {
              throw err;
            });
        }

        // Start the token refresh process
        const token = getToken().refresh;

        const store = useAuthStore();
        if (!token) {
          store.logout();
          postLogout();
          return Promise.reject(error);
        }

        // @ts-ignore
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          store.refreshToken()
            .then((data) => {
              if (originalRequest.headers) {
                originalRequest.headers.Authorization = "Bearer " + data.access;
              }
              processQueue(null, data.access);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              postLogout();
            })
            .finally(() => {
              isRefreshing = false;
            })
        });
      }
      return Promise.reject(error);
    };


const processQueue = (error: any, token: string | null) => {
  requestQueue.forEach((prom) => {
    if (error !== null || token === null) {
      prom.reject();
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
};
