<template lang="pug">
header.header()
  .top-bar
  .header-content.flex.flex-row.justify-between.items-center(class="max-md:pl-5 max-md:pr-5 md:pl-10 md:pr-10 max-md:flex-row-reverse")
    img.header-logo.object-contain(:src="switchToSmallLogo ? logoPathSmall : logoPath" class="md:hidden")
    .empty-header(v-if="!hasMenu")
    .header-navigation()
      .breadcrumbs()
        el-dropdown(v-if="app" ref="appMenu" trigger="click" :hide-on-click="true")
          .flex.align-center
            span {{ app.title }}
            el-icon(class="el-icon--right")
              ArrowDown  
          template(#dropdown)
            el-dropdown-menu()
              el-dropdown-item()
                router-link.w-full(to="/")  {{ $t('home') }}
              el-dropdown-item(v-for="a in apps" :key="a.id")
                router-link.w-full(:to="a.link" @click="onAppRouteClick") {{ a.title }} 
        template(v-if="mq.mdPlus")
          router-link.breadcrumb(
            v-for="item in breadcrumbs"
            :key="item.name",
            :to="item.route",
            exact
          ) {{ item.keepLabelWithoutTranslation ? item.label : $t(item.label) }}

    .flex.flex-row.items-center(class="max-md:hidden")
      .mr-1.text-lg() {{ title }}
      img.header-logo.object-contain(:src="switchToSmallLogo ? logoPathSmall : logoPath")
    .menu-button.cursor-pointer(class="md:hidden" @click="isMenuCollapsed = !isMenuCollapsed")
      el-icon.rotate-90
        MoreFilled(v-if="isMenuCollapsed")
        Close(v-else)
    //- Back button is used on mobile to navigate to a screen that is one level up in the breadcrumbs, if it exists
    //- Note that the button order is also reversed on mobile, menu is on the right side
    //- .back-button(v-if="previousView !== null && previousView !== undefined" class="md:hidden")
    //-   router-link(v-if="currentRoute !== '/'" :to="previousView.route")
    //-     .flex.flex-row.items-center
    //-       el-icon(size="30")
    //-         ArrowLeft
    //-       //- div {{ $t('back') }}

</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import type { PropType } from "vue";
import { useAuthStore } from "@shared/stores/auth";
import { useRoute, useRouter } from 'vue-router';
import { useMenuStore, type BreadCrumb } from '../stores/menu';
import { useMq } from 'vue3-mq';
import { useAppStore } from '../stores/apps';
import type { IElDropdownInstance } from 'element-plus'

const mq = useMq();

type NavbarSetting = {
  name: string;
  i18n: boolean;
  route: { name: string };
  icon: string;
};

const props = withDefaults(defineProps<
  {
    postLogout: Function,
    title?: string,
    logoPath: string,
    logoPathSmall: string
  }
>(), {
  postLogout: () => { },
  title: "Smart Monitoring",
  logoPath: "",
  logoPathSmall: ""
});

const router = useRouter();
const menuStore = useMenuStore();
const appStore = useAppStore();

const hasMenu = computed(() => menuStore.menu !== null)

const appsLoadingState = computed(() => appStore.appsLoadingState)
const apps = computed(() => appStore.sortedResult)

const isMenuCollapsed = computed({
  get() {
    return !menuStore.isMenuVisible;
  },
  set(value) {
    menuStore.setMenuVisible(!value);
  }
});

const switchToSmallLogo = computed(() => {
  return props.logoPathSmall !== "" && !mq.mdPlus;
});

const logout = () => {
  useAuthStore().logout();
  if (props.postLogout) {
    props.postLogout();
  }
};

const appMenu = ref();

function onAppRouteClick() {
  if(appMenu.value) {
    appMenu.value.handleClose();
  }
}

function goToRoute(setting) {

  if (mq.mdMinus) {
    isMenuCollapsed.value = true;
  }
  router.push(setting.route);
}


const app = computed(() => {
  if(router.currentRoute.value.meta?.breadcrumbs !== undefined) {
    return apps.value.find(item => item.clientId === router.currentRoute.value.meta.appClientId)
  }
  return null;
})

const nonAppBreadCrumbs = computed(() => {
  if (breadcrumbs.value && breadcrumbs.value.length > 0) {
    return breadcrumbs.value.slice(1);
  }
  return null;
})


const breadcrumbs = computed<BreadCrumb[]>(() => {
  if (menuStore.breadCrumbs !== null) {
    return menuStore.breadCrumbs;
  }
  return router.currentRoute.value.meta.breadcrumbs as BreadCrumb[];
});

const previousView = computed(() => {
  if (menuStore.breadCrumbs !== null && menuStore.breadCrumbs.length >= 2) {
    return menuStore.breadCrumbs[menuStore.breadCrumbs.length - 2]
  }
  return {
    label: "home",
    route: { name: "home" }
  };
});

const route = useRoute();
const currentRoute = computed(() => route.path)


</script>

<style scoped>

.menu-button {
  /* same width as logo on mobile */
  width: 40px;
}

.breadcrumb {
  color: var(--color-grey-dark);
  /* color: var(--color-grey); */
}

.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbs>*+*:before {
  content: " / ";
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: var(--color-grey);
}

.el-menu-item i {
  color: white !important;
}

.link-icon {
  max-width: 14px;
}

.menu-vertical {
  text-align: left;
  color: white;
  z-index: 9;
}

.menu-vertical:not(.el-menu--collapse) {
  width: 250px;
  min-height: 100vh;
}

.el-menu--collapse {
  display: none;
}

#nav-icon1 span {
  display: block;
  margin: 4px 0;
  height: 2px;
  width: 28px;
  background: var(--color-grey-dark);
}

.router-link-active {
  color: var(--color-orange-dark);
}

.setting:hover {
  background-color: var(--color-primary);
}

.header-logo {
  height: 40px;
}

.header-navigation {
  display: flex;
  align-items: center;
  width: auto;
}

.separator {
  width: 2px;
  height: 32px;
  background-color: var(var(--color-grey)-light);
}

.header {
  z-index: 1000;
  position: sticky;
  top: 0;
  left: 0;
  height: 56px;
  background-color: white;
  /* background-color: var(--color-background); */
  /* border-bottom: 1px solid var(--color-grey); */
  width: 100%;
}

.header-content {
  box-shadow: 0 4px 6px 0 var(--color-shadow);
  white-space: nowrap;
  min-height: 55px;
  height: 55px;
  background-color: var(--color-background);
  margin: auto;
  z-index: 10;
  position: relative;
  top: 0;
}


.header-user-profile-container {
  z-index: 10;
}

.header-nav {
  z-index: 5;
  position: relative;
  width: 100%;
  justify-content: center;
}

.user-menu {
  position: relative;
  right: 0;
  background: white;
  border: 1px solid var(--color-grey);
}

.user-menu>* {
  padding: 10px;
}

.header-profile {
  position: relative;
  z-index: 10;
}

.header-logo {
  z-index: 10;
}

.header-title {
  font-size: 24px;
  margin-right: 1rem;
}

.header-logo-img {
  height: 34px;
  width: 24px;
}

.header-profile {
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
}

.navbar-item {
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .hide-on-small {
    display: none;
  }
}

.top-bar {
  background-image: linear-gradient(90deg, var(--color-orange-dark), #212231);
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 8px;
  width: 100%;
  z-index: 10;
}

.back-button {
  margin-left: -9px;
}
</style>
