import { useAppStore } from '@shared/stores/apps';
import { whArticlesMultiple, whArticlesSingle, whSingleArticles } from '../constants';
import { onMounted } from 'vue';

export function initWarehouseApp() {
  const appStore = useAppStore();

  onMounted(() => {
    appStore.setApplinks([
      {
        uriExpression: 'twin-edit.' + whArticlesSingle,
        routeName: "warehouseEditSingle"
      },
      {
        uriExpression: 'twin-edit.' + whArticlesMultiple,
        routeName: "warehouseEditMultiple"
      },
      {
        uriExpression: 'twin-edit.' + whSingleArticles,
        routeName: "warehouseEditSingleArticle"
      },
    ]);
  })
}