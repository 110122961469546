export const twinModellingRoutes = [
  {
    path: "/asset-admin",
    name: "assetAdmin",
    component: () => import("@shared/twinModelling/views/TemplateManagement.vue"),
    meta:
    {
      appClientId: "smx-model-mgmt",
      breadcrumbs: [{
        label: 'templateManagement',
        route: { name: "assetAdmin" }
      }]
    }
  },
  {
    path: "/asset-admin/permissions",
    name: "permissions",
    props: true,
    component: () => import("@shared/views/PermissionManagement.vue"),
    meta: {
      appClientId: "smx-model-mgmt",
      breadcrumbs: [{
        label: 'Permissions',
        route: { name: "assetAdmin" }
      }]
    }
  },
  {
    path: "/asset-admin/edit/:templateId",
    name: "assetAdminEdit",
    props: true,
    component: () => import("@shared/twinModelling/views/TemplateManagementDetail.vue"),
    meta: {
      appClientId: "smx-model-mgmt",
      breadcrumbs: []
    }
  },
  {
    path: "/asset-admin/input-values/:id",
    name: "assetAdminInputValuesDetail",
    props: true,
    component: () => import("@shared/components/twinManagement/InputValueDetailView.vue"),
    meta:
    {
      appClientId: "smx-model-mgmt",
      breadcrumbs: [
        {
          label: 'inputValues',
          route: { name: "assetAdminInputValues" }
        },
      ]
    }
  },
  {
    path: "/asset-admin/input-values",
    name: "assetAdminInputValues",
    props: true,
    component: () => import("@shared/components/twinManagement/InputValueAdmin.vue"),
    meta:
    {
      appClientId: "smx-model-mgmt",
      breadcrumbs: [
        {
          label: 'inputValues',
          route: { name: "assetAdminInputValues" }
        },
      ]
    }
  },


]