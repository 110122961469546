import { useAuthStore } from "@shared/stores/auth";
import { useAppStore } from "@shared/stores/apps";
import { onBeforeMount, onMounted } from "vue";
// import duration from 'dayjs/plugin/duration';
import dayjs from "dayjs";
import { localStorageMachineRestrictionKey } from "../constants";

export function initTicketManagementApp() {
  const appStore = useAppStore();
  const authStore = useAuthStore();
  // dayjs.extend(duration);

  onBeforeMount(() => {
    authStore.setPersistentStorageValue(localStorageMachineRestrictionKey);
  });

  onMounted(() => {
    appStore.setApplinks([
      {
        uriExpression: "twin-edit.tm.ticket.*",
        routeName: "tmTicketDetail",
      },
    ]);
  });
}
