import { clientId } from "./constants";

export const assetManagementRoutes = [
  {
    path: "/asset-management",
    name: "assetManagement",
    component: () => import("@shared/assetManagement/views/AssetTypeSelection.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [{
        label: 'assets',
        route: { name: "assetManagement" }
      }]
    }
  },
  {
    path: "/asset-management/:template",
    name: "assetManagementList",
    props: true,
    component: () => import("@shared/assetManagement/views/AssetManagementListView.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'assets',
          route: { name: "assetManagement" }
        },
      ]
    }
  },
  {
    path: "/asset-management/:template/edit/:id?",
    name: "assetManagementDetail",
    props: true,
    component: () => import("@shared/assetManagement/views/AssetManagementDetailView.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'assets',
          route: { name: "assetManagement" }
        }
      ]
    }
  },
  {
    path: "/asset-reports/assets",
    name: "assetReports",
    component: () => import("@shared/assetManagement/views/ReportAssets.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'assets',
          route: { name: "assetReports" }
        }
      ]
    }
  },
  {
    path: "/asset-reports/changelogs",
    name: "assetReportChangelogs",
    component: () => import("@shared/assetManagement/views/ReportAssetsChangelog.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'changelog',
          route: { name: "assetReportChangelogs" }
        }

      ]
    }
  },
  {
    path: "/asset-access",
    name: "assetAccess",
    component: () => import("@shared/assetManagement/views/AssetManagementAccess.vue"),
    meta:
    {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: 'accessRights',
          route: { name: "assetAccess" }
        }

      ]
    }
  },

]