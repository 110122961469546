<template lang="pug">
el-config-provider(:locale="elementPlusLanguage")
  SmxBaseLayout
    template(#navbar)
      Navbar(          
        v-if="!isPublicPage"
        :post-logout="logoutCallback"
        title=""
        logo-path="/img/logo.png"
        logo-path-small="/img/logo-small.png"
        )
    template(#menu)
      SmxMenu(v-if="!isPublicPage")        
    .app-content(
      class="md:p-10 max-md:p-5")
      RouterView(:key="route.path")
</template>

<script setup lang="ts">
import { computed, onMounted, watch, ref } from 'vue'
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router'
import Navbar from '@shared/components/Header.vue'
import router from '@schulz/router'
import { useI18n } from 'vue-i18n'
import { publicPages } from '@shared/router/loginGuard'
import { useAuthStore } from '@shared/stores/auth'
import { useAppStore } from '@shared/stores/apps'
import de from 'element-plus/dist/locale/de.mjs'
import en from 'element-plus/dist/locale/de.mjs'
import SmxMenu from '@shared/components/SmxMenu.vue'
import { useMenuStore } from '@shared/stores/menu'
import SmxBaseLayout from '@shared/views/SmxBaseLayout.vue'
import { useMyAccountStore } from '@shared/stores/myAccount'
import { whArticlesSingle, whArticlesMultiple } from '@shared/warehouse/constants'
import { initAssetManagementApp } from '@shared/assetManagement/hooks/initAssetManagement'
import { initWarehouseApp } from '@shared/warehouse/hooks/warehouseInit'
import { initTicketManagementApp } from '@shared/maintenance/hooks/initTicketManagement'
import { useKeyCloakAuthentication } from '@shared/hooks/authentication'
import useMenu from '@shared/hooks/menu'
import { initItAssetManagementApp } from '@shared/itAssetManagement/hooks/initItAssetManagement'

const { t } = useI18n()

const myAccountStore = useMyAccountStore()
useKeyCloakAuthentication()
const { isPublicPage } = useMenu()

const elementPlusLanguage = computed(() => {
  switch (myAccountStore.language) {
    case 'en':
      return en
    default:
      return de
  }
})

const components = {
  Navbar
}

const appStore = useAppStore()

const menuStore = useMenuStore()

const logoutCallback = () => {
  router.push({ name: 'login' })
}

initWarehouseApp()
initAssetManagementApp()
initItAssetManagementApp()
initTicketManagementApp()

onMounted(() => {
  appStore.loadAppsOverview({}, false)
})

const route = useRoute()
</script>

<style>
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.app-body {
  display: flex;
}

.app-content {
  width: 100%;
}

:root {
  /* --el-color-primary: #409eff;
  --el-color-primary-light-3: #3375b9;
  --el-color-primary-light-5: #2a598a;
  --el-color-primary-light-7: #213d5b;
  --el-color-primary-light-8: #1d3043;
  --el-color-primary-light-9: #18222c;
  --el-color-primary-dark-2: #66b1ff;
  --el-color-success: #67c23a;
  --el-color-success-light-3: #4e8e2f;
  --el-color-success-light-5: #3e6b27;
  --el-color-success-light-7: #2d481f;
  --el-color-success-light-8: #25371c;
  --el-color-success-light-9: #1c2518;
  --el-color-success-dark-2: #85ce61;
  --el-color-warning: #e6a23c;
  --el-color-warning-light-3: #a77730;
  --el-color-warning-light-5: #7d5b28;
  --el-color-warning-light-7: #533f20;
  --el-color-warning-light-8: #3e301c;
  --el-color-warning-light-9: #292218;
  --el-color-warning-dark-2: #ebb563;
  --el-color-danger: #f56c6c;
  --el-color-danger-light-3: #b25252;
  --el-color-danger-light-5: #854040;
  --el-color-danger-light-7: #582e2e;
  --el-color-danger-light-8: #412626;
  --el-color-danger-light-9: #2b1d1d;
  --el-color-danger-dark-2: #f78989;
  --el-color-error: #f56c6c;
  --el-color-error-light-3: #b25252;
  --el-color-error-light-5: #854040;
  --el-color-error-light-7: #582e2e;
  --el-color-error-light-8: #412626;
  --el-color-error-light-9: #2b1d1d;
  --el-color-error-dark-2: #f78989;
  --el-color-info: #909399;
  --el-color-info-light-3: #6b6d71;
  --el-color-info-light-5: #525457;
  --el-color-info-light-7: #393a3c;
  --el-color-info-light-8: #2d2d2f;
  --el-color-info-light-9: #202121;
  --el-color-info-dark-2: #a6a9ad; */
  /* --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.36), 0px 8px 20px rgba(0, 0, 0, 0.72);
  --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, 0.72);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.72);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.72), 0px 12px 32px #000000,
    0px 8px 16px -8px #000000; */
  /* --el-bg-color-page: #0a0a0a;
  --el-bg-color: #141414;
  --el-bg-color-overlay: #1d1e1f; */
  /* --el-text-color-primary: #e5eaf3;
  --el-text-color-regular: #cfd3dc;
  --el-text-color-secondary: #a3a6ad;
  --el-text-color-placeholder: #8d9095;
  --el-text-color-disabled: #6c6e72; */
  --el-border-color-darker: #0d0d0e;
  --el-border-color-dark: #1f1f20;
  --el-border-color: #3c3c41;
  --el-border-color-light: #c3ccd8;
  --el-border-color-lighter: #d0d0eb;
  --el-border-color-extra-light: #9e9ead;
  /* --el-fill-color-darker: #424243;
  --el-fill-color-dark: #39393a;
  --el-fill-color: #303030;
  --el-fill-color-light: #262727;
  --el-fill-color-lighter: #1d1d1d;
  --el-fill-color-extra-light: #191919;
  --el-fill-color-blank: transparent; */
  /* --el-mask-color: rgba(0, 0, 0, 0.8);
  --el-mask-color-extra-light: rgba(0, 0, 0, 0.3); */
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
}
</style>
