<template lang="pug">
.menu-item.mb-3(v-for="item in props.content" :key="item.name")
  .flex.justify-between(@click="() => openDropDown(item.id)")
    .category(v-if="item.type === 'category'") 
      .text-gray-400.capitalize {{ item.translate ? $t(item.name) : item.name }}
    .action.cursor-pointer(v-if="item.type === 'action'") 
      .capitalize(@click="item.onClick") {{ item.translate ? $t(item.name) : item.name }}
    .router-link(v-else-if="item.type === 'routerlink'") 
      router-link(:to="item.route" :active="true" exact @click="linkClicked") 
        span {{ item.translate ? $t(item.name) : item.name }}
    el-icon.dropdown-icon(v-if="item.isDropDown" :style="{transform: `rotate(${openDropDownMenus[item.id] ? 180 : 0}deg)`}")
      CaretBottom
  .children.ml-4(v-if="item.children && (!item.isDropDown || openDropDownMenus[item.id])")
    SmxMenuItem(:content="item.children" @link-clicked="linkClicked")
</template>

<script setup lang="ts">
import { type SmxMenuItem } from '@shared/stores/menu';
import { useMenuStore } from '../stores/menu';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ 
  content: SmxMenuItem[]
}>(), {
  content: () => []
});

const emit = defineEmits(['link-clicked'])

function linkClicked() {
  emit('link-clicked')
}

const menuStore = useMenuStore();

const openDropDownMenus = computed(() => menuStore.openDropDownMenus);

function openDropDown(id: string){
  menuStore.openDropDownMenus[id] = !(menuStore.openDropDownMenus[id] ?? false);
}

</script>

<style scoped>
.dropdown-icon {
  transition-duration: 0.5s;
  transition-property: transform;
}

</style>

<style scoped>
.menu-item {
  display: flex;
  flex-direction: column;
}

.menu-item > *+* {
  margin-top: 0.5rem;
}

.children > *+* {
  margin-top: 1rem;
}

.router-link-active {
  color: var(--color-orange-dark);
}

</style>