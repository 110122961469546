import axios from 'axios'
import { PaginationQuery, convertToUrlParams } from './common';

export interface AppItem {
  id: string,
  clientId: string, 
  title: string,
  description: string,
  language: string,
  defaultLanguage: string,
  link: string,
  icon: string,
  order: number, 
  isActive: boolean
}


export interface AppRole {
  uuid: string, 
  name: string, 
  app: string, 
}


export async function patchAppSettings(appId: string, settings: AppItem) {
  return axios.patch(`/api/portal/apps/${appId}`, settings);
}

export async function postAppSettings(settings: AppItem) {
  return axios.post("/api/portal/apps/", settings);
}

export async function getApps(query: PaginationQuery & Record<'language', string>) {
  // TODO: this is a backend bug remove when fixed https://github.com/inctec/smx-foundation/issues/121
  query.language = 'de'
  return axios.get(`/api/portal/apps?${convertToUrlParams(query)}`)
}

export async function getAppsAdmin() {
  return axios.get('/api/portal/apps?admin=true')
}

export async function postAppRole(role: { app: string, name: string }) {
  return axios.post(`/api/portal/roles`, role);
}

export async function deleteAppRole(roleId: string) {
  return axios.delete(`/api/portal/roles/${roleId}`)
}