import { useAuthStore } from '@shared/stores/auth';
import { onMounted, onUnmounted } from 'vue';

/**
 * This should be added in order to refresh the token regularly. 
 * This is to prevent situations where the user is adding data and then being logged out https://github.com/inctec/smx-portal/issues/144
 */
export function useKeyCloakAuthentication() {
  const authStore = useAuthStore();
  authStore.provider = "KeyCloak";

  function refreshToken() {
    authStore.refreshToken();
  }

  onMounted(() => {
    authStore.setRealmName("inctec-portal");
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible" && authStore.tokenState.refresh !== null) {
        refreshToken();
      }
    });
  })

  onUnmounted(() => {
    document.removeEventListener("visibilitychange", refreshToken);
  })
}

