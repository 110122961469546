import { useAuthStore } from "../stores/auth";
export function loginGuard(to: any, from: any, next: any): any {
  const privatePage = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  if (privatePage && authStore.tokenState.refresh === null) {
    // TODO: send route to login as next
    return next({ name: "login", query: { next: encodeURI(to.fullPath) } });
  }
  next();
}

export const publicPages = [
  "/login",
  "/register",
  "/register-organization",
  "/forgot-password",
  "/reset-password",
];
